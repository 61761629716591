import React from 'react';
import classnames from 'classnames';
import { Link } from 'components/link/Link';

import s from './TopNavigation.module.scss';

type Props = {
  isActive?: boolean;
  children: React.ReactNode;
  href?: string;
  onClick?: (e: any) => void;
};

export const SectionItem = ({ isActive, href, children, onClick = () => {} }: Props) => {
  const classes = classnames(s.sectionItem, { [s.isActive]: isActive });

  if (href) {
    return (
      <Link className={classes} href={href} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  );
};
