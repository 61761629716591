import React from 'react';
import classnames from 'classnames';

import { ILink } from 'utils/types';
import { Link } from 'components/link/Link';

import s from './Navigation.module.scss';

type Props = {
  link: ILink;
  isActive?: boolean;
  children: React.ReactNode;
};

export const NavigationItem = ({ link, isActive, children }: Props) => {
  return (
    <div className={s.item}>
      <Link className={classnames(s.item__link, { [s.isActive]: isActive })} href={link.href} uid={link.uid}>
        {children}
      </Link>
    </div>
  );
};
