import React from 'react';
import classNames from 'classnames';

import s from './GridOverlay.module.scss';

type Props = {
  columns?: number;
  baseline?: number;
  noPanel?: boolean;
};

export const GridOverlay = ({ columns = 12 }: Props) => {
  React.useEffect(() => {
    const gridEl = document.getElementById('gridOverlay');

    gridEl!.style.setProperty('--grid-column-count', columns.toString());
  }, []);

  return (
    <div className={classNames(s.grid, { [s.verticalIsVisible]: true })} id="gridOverlay">
      <div className={s.grid__container}>
        <div className={s.grid__row} data-columns={columns}>
          {Array(columns)
            .fill(0)
            .map((_, i) => (
              <div key={`grid_column_${i}`} className={s.grid__column}>
                <div className={s.grid__visualize} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default GridOverlay;
