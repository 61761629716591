import React from 'react';
import classnames from 'classnames';

import { ILink } from 'utils/types';
import { Link } from 'components/link/Link';

import s from './TopNavigation.module.scss';

type Props = {
  link: ILink;
  isActive?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
};

export const TopNavigationItem = ({ link, isActive, onClick, children }: Props) => {
  return (
    <div className={s.item}>
      <Link
        className={classnames(s.item__link, { [s.isActive]: isActive })}
        onClick={onClick}
        // {...link}
        href={link.href}
        uid={link.uid}
      >
        {children}
      </Link>
    </div>
  );
};
