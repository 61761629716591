import React from 'react';
import classnames from 'classnames';

import s from './Buttons.module.scss';

type Props = {
  title?: React.ReactNode;
  alignment?:
    | 'left'
    | 'center'
    | 'right'
    | 'single column'
    | 'two column'
    | 'three column'
    | 'four column'
    | string;
  isSlice?: boolean;
  isArticle?: boolean;
  children: React.ReactNode;
};

export const Buttons = ({ title, alignment = 'left', isSlice, isArticle, children }: Props) => {
  const content = <div className={s.buttons__list}>{children}</div>;

  const container = (
    <div className={s.buttons__segment}>
      <div className={s.buttons__container}>
        <div className={s.buttons__row}>
          <div className={s.buttons__col}>
            {title && (
              <div className={s.buttons__header}>
                {typeof title === 'string' ? (
                  <h2 className={s.buttons__title}>{title}</h2>
                ) : (
                  <div className={s.buttons__titleWrap}>{title}</div>
                )}
              </div>
            )}
            {content}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classnames(s.buttons, s[alignment], { [s.article]: isArticle })}>
      {isSlice ? container : content}
    </div>
  );
};
