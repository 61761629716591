import React from 'react';
import classnames from 'classnames';

import s from './TopNavigation.module.scss';

type Props = {
  isActive?: boolean;
  children?: React.ReactNode;
};

export const Search = ({ isActive, children }: Props) => {
  return (
    <div className={classnames(s.search, { [s.isActive]: isActive })}>
      <div className={s.search__layout}>{children}</div>
    </div>
  );
};
