import React from 'react';
import { useRouter } from 'next/router';
import { useClickOutside } from '@alvogen/shared//hooks/useClickOutside';
import classnames from 'classnames';

import { useUI } from 'utils/ui';
import { IImage, ILink } from 'utils/types';
import { Link } from 'components/link/Link';
import { TopNavigation } from 'components/top-navigation/TopNavigation';
import { Menu } from 'components/top-navigation/Menu';
import { SectionItem } from 'components/top-navigation/SectionItem';
import { TopNavigationItem } from 'components/top-navigation/TopNavigationItem';
import { Search } from 'components/top-navigation/Search';
import { SearchBox } from 'components/search/SearchBox';
import { Item } from '@alvogen/shared/components/data/Item';

type Props = {
  navigationMain: Array<{
    title: string;
    link: ILink;
    image: IImage;
    items: Array<{
      title: string;
      link: ILink;
      nested: boolean;
      showInNavigation: boolean;
    }>;
  }>;
  navigationFooter: Array<{
    text: string;
    link: ILink;
  }>;
  languages?: Array<{
    label: string;
    link: ILink;
  }>;
};

export const TopNavigationContainer = ({ navigationMain, navigationFooter, languages }: Props) => {
  const router = useRouter();
  const ui = useUI();
  const navigationRef = React.useRef();
  const [activeIndex, setActiveIndex] = React.useState(-1);

  useClickOutside(navigationRef, () => ui.toggleTopNavigation(false));

  const onScroll = () => {
    ui.toggleTopNavigation(false);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onSectionClick = (i) => {
    ui.toggleNavigation(false);
    ui.toggleTopNavigation(true);
    setActiveIndex(i);
  };

  const onSearchClick = (i) => {
    ui.toggleNavigation(false);
    ui.toggleTopNavigation(true);
    setActiveIndex(-1);
  };

  const closeMenu = () => {
    ui.toggleNavigation(false);
    ui.toggleTopNavigation(false);
  };

  const isSectionActive = (router, slug) => {
    if (!router.query.parentId) {
      const split = router.asPath.split('/').filter((x) => x !== '');
      const first = split[0];
      const locale = router.locale;

      if (`/${locale}/${first}` === slug) {
        return `/${locale}/${first}` === slug;
      }

      return `/${first}` === slug;
    }

    return router.query.parentId === slug;
  };

  const isItemActive = (router, uid) => {
    if (`/${router.locale}${router.asPath}` === uid) {
      return `/${router.locale}${router.asPath}` === uid;
    }

    return router.asPath === uid;
  };

  return (
    <TopNavigation
      ref={navigationRef}
      isOpen={ui.isTopNavigationOpen}
      menuIndex={activeIndex}
      onToggleSearch={onSearchClick}
      languages={languages}
      topNav={navigationFooter?.map((item) => (
        <Link
          key={item.text}
          onClick={() => ui.toggleTopNavigation(false)}
          className={classnames()}
          // {...item.link}
          href={item.link?.href}
          uid={item.link?.uid}
          // isActive={isItemActive(router, item.link.href)}
        >
          {item.text}
        </Link>
      ))}
      mainNav={navigationMain
        ?.filter((item) => !!item.link)
        .map((nav, index) => {
          if (nav.items.length > 0) {
            return (
              <SectionItem
                key={index}
                onClick={() => onSectionClick(index)}
                isActive={isSectionActive(router, nav.link.href)}
              >
                {nav.title}
              </SectionItem>
            );
          } else {
            return (
              <SectionItem
                key={index}
                href={nav.link.href as string}
                onClick={closeMenu}
                isActive={isSectionActive(router, nav.link.href)}
              >
                {nav.title}
              </SectionItem>
            );
          }
        })}
    >
      {navigationMain?.map((nav, index) => {
        return (
          <Menu key={index} image={nav.image} isActive={activeIndex === index}>
            {nav.items
              .filter((item) => item.showInNavigation)
              .filter((item) => !!item.link)
              .map((item, i) => (
                <TopNavigationItem
                  key={i}
                  link={item.link}
                  isActive={isItemActive(router, item.link.href)}
                  onClick={() => ui.toggleTopNavigation(false)}
                >
                  <div key={i}>{item.title}</div>
                </TopNavigationItem>
              ))}
          </Menu>
        );
      })}
      <Search isActive={activeIndex === -1}>
        <SearchBox
          focus={ui.isTopNavigationOpen && activeIndex === -1}
          onSearched={() => ui.toggleTopNavigation(false)}
        />
      </Search>
    </TopNavigation>
  );
};
