import React from 'react';
import classnames from 'classnames';
import { TimelineMax } from 'gsap';

import s from './MenuButton.module.scss';

let timelineCache;

type Props = {
  isOpen?: boolean;
  isDisable?: boolean;
  color?: 'white' | 'black';
  showDesktop?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const MenuButton = ({
  isOpen,
  isDisable,
  color = 'black',
  showDesktop = true,
  children,
  ...rest
}: Props) => {
  const [isActuallyOpen, setState] = React.useState(false);
  const className = classnames(s.button, {
    [s.isOpen]: isOpen,
    [s.isDisable]: isDisable,
    [s.hideDesktop]: !showDesktop,
  });
  const menuEl = React.useRef<HTMLDivElement>(null);
  const crossEl = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!menuEl.current || !crossEl.current) {
      return;
    }

    if (timelineCache) {
      timelineCache.kill();
    }

    const t = new TimelineMax({ paused: true });
    const line1 = menuEl.current.querySelector('span:first-child');
    const line2 = menuEl.current.querySelector('span:last-child');
    const ease = 'Power4.easeInOut';

    t.fromTo(line1, 1, { y: '0%' }, { y: '100%', ease }, 0);
    t.fromTo(line2, 1, { y: '0%' }, { y: '-100%', ease }, 0);
    t.fromTo(crossEl.current, 0.5, { opacity: 0 }, { opacity: 1 });

    timelineCache = t;

    if (isOpen && !isActuallyOpen) {
      t.play(0);
    } else if (isActuallyOpen) {
      t.reverse(0);
    }

    setState(!!isOpen);
  }, [isOpen]);

  const content = (
    <>
      <span className={s.button__icons}>
        <span ref={menuEl} className={s.button__icon}>
          <span className={s.button__line} />
          <span className={s.button__line} />
        </span>

        <span ref={crossEl} className={s.button__close}>
          <span className={s.button__cross} />
          <span className={s.button__cross} />
        </span>
      </span>
    </>
  );

  return (
    <button className={className} {...rest} aria-label="Toggle navigation" tabIndex={-1}>
      {content}
    </button>
  );
};
