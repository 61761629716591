import React from 'react';
import Link from 'next/link';
import { Trans } from '@lingui/macro';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import useOnClickOutside from 'use-onclickoutside';
import IconGlobe from 'components/assets/icon-globe.svg';
import { ILink } from 'utils/types';

import { config } from 'utils/config';

import s from './Languages.module.scss';

type Props = {
  languages?: Array<{
    label: string;
    link: ILink;
  }>;
  mobile?: boolean;
};

export const Languages = ({ languages, mobile }: Props) => {
  const router = useRouter();
  const [isOpen, setOpen] = React.useState(false);
  const ref = React.useRef(null);

  useOnClickOutside(ref, () => setOpen(false));

  if (
    (languages?.length < 1 || (languages && languages[0]?.label === '')) &&
    router.locales.length === 1
  ) {
    return null;
  }

  return (
    <div className={classnames(s.languages, { [s.open]: isOpen, [s.mobile]: mobile })}>
      <button className={s.languages__button} onClick={() => setOpen((x) => !x)}>
        <IconGlobe /> <Trans>Language</Trans>
      </button>

      <div className={s.languages__label}>
        <IconGlobe /> <Trans>Languages</Trans>
      </div>

      <ul ref={ref} className={s.languages__list}>
        {router.locales.map((locale) => (
          <li key={locale} className={s.languages__item}>
            <Link locale={locale} href="/">
              <a className={s.languages__link}>{config.i18n.displayMap[locale]}</a>
            </Link>
          </li>
        ))}
        {languages
          ?.filter((language) => language.link?.href)
          .map((language) => (
            <li key={language.link?.href} className={s.languages__item}>
              <Link {...language.link}>
                <a className={s.languages__link}>
                  {language.label || <Trans>Label missing</Trans>}
                </a>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};
