import { config } from './config';

type Doc = {
  type: string;
  uid: string;
  id?: string;
  tags?: string[];
  slug?: string;
  lang?: string;
  link_type?: string;
  isBroken?: boolean;
};

export const langResolver = (doc: Doc, redirects?: Array<{ from: string; to: string }>): string => {
  const defaultLocale = config.i18n.default;
  const locales = config.i18n.languages;
  const prismicLocales = config.i18n.prismic;

  const lang = locales[prismicLocales.indexOf(doc.lang)];

  if (lang !== defaultLocale && lang !== undefined) {
    const langPrefix = `/${lang}`;
    const slug = prismicResolver(doc, redirects);

    if (slug.startsWith(langPrefix)) {
      return slug;
    }

    return `${langPrefix}${slug}`;
  }

  return prismicResolver(doc, redirects);
};

export const prismicResolver = (
  doc: Doc,
  redirects?: Array<{ id?: string; from: string; to: string }>
): string => {
  switch (doc.type) {
    // if (doc.link_type === 'Web' || doc.link_type === 'Media') {
    //   return {
    //     href: doc.url || '/',
    //     target: doc.target,
    //     external: true,
    //   };
    // }

    case 'home':
      return '/';
    case 'newsroom':
      return '/newsroom';
    case 'articles':
      return '/articles';

    case 'page':
    case 'split_page':
      const url = `/${doc.uid}`;

      if (redirects) {
        const found = redirects.find((r) => r.id === doc.id);

        if (found) {
          return found.to;
        }
      }

      return url;

    case 'article':
      return `/newsroom/${doc.uid}`;
    case 'event':
      return `/events/${doc.uid}`;
    case 'person':
      return `/team/${doc.uid}`;
    case 'job':
      return `/jobs/${doc.uid}`;
    case 'product':
    case 'basic_product':
    case 'product_iceland':
      return `/product/${doc.uid}`;
    case 'story':
      return `/stories/${doc.uid}`;
    case 'broken_type':
      return '/404';
  }

  return `#unresolved-${doc.type}`;
};

export const linkResolver = (doc, redirects?): string => {
  return langResolver(doc, redirects);
};
