import React from 'react';
import { Link } from 'components/link/Link';
import { ILink } from 'utils/types';

import s from './Navigation.module.scss';

type Props = {
  link: ILink;
  children: React.ReactNode;
};

export const NavigationFooterItem = ({ link, children }: Props) => {
  if (!link) {
    return null;
  }

  return (
    <div className={s.footerItem}>
      <Link
        className={s.footerItem__link}
        // {...link}
        href={link.href}
        uid={link.uid}
      >
        {children}
      </Link>
    </div>
  );
};
