import React from 'react';
import classnames from 'classnames';
import { TimelineMax } from 'gsap';

import { Header } from 'components/header/Header';
import { MenuButton } from 'components/button/MenuButton';
import { SearchBox } from 'components/search/SearchBox';

import s from './Navigation.module.scss';

type Props = {
  isOpen?: boolean;
  isAnimating?: boolean;
  showDesktop?: boolean;
  onToggle?: () => void;
  children: React.ReactNode;
  footer: React.ReactNode;
  languages?: React.ReactNode;
};

export type NavigationRef = {
  open: () => void;
  close: () => void;
  setImage: (index: number) => void;
  changeImage: (index: number) => void;
};

export const Navigation = React.forwardRef(
  (
    { isOpen, isAnimating, showDesktop, onToggle, children, footer, languages }: Props,
    ref: React.Ref<NavigationRef>
  ) => {
    const wrapEl = React.useRef<HTMLDivElement | null>(null);
    const headerEl = React.useRef<HTMLDivElement | null>(null);
    const mainEl = React.useRef<HTMLDivElement | null>(null);
    const brandEl = React.useRef<HTMLDivElement | null>(null);
    const backgroundEl = React.useRef<HTMLDivElement | null>(null);
    const imageRef = React.useRef<HTMLDivElement | null>(null);
    const searchRef = React.useRef<HTMLLIElement | null>(null);
    const footerRef = React.useRef<HTMLDivElement | null>(null);

    const maskRef = React.useRef<HTMLDivElement | null>(null);
    const brandRef = React.useRef<HTMLDivElement | null>(null);

    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

    React.useImperativeHandle(ref, () => ({
      open() {
        const t = new TimelineMax();

        t.set(wrapEl.current, { opacity: 1 });
        // t.set(mainEl.current, { display: 'block' });
        t.fromTo(brandEl.current, 1, { x: '100%' }, { x: '0%', ease: 'Power3.easeOut' }, 0);
        t.fromTo(
          backgroundEl.current,
          0.8,
          { x: '100%' },
          { x: '0%', ease: 'Power2.easeOut' },
          0.2
        );
        t.fromTo(
          mainEl.current,
          0.2,
          {
            opacity: 0,
          },
          { opacity: 1 },
          '-=0.5'
        );

        t.call(() => {
          if (!headerEl.current) return;
          headerEl.current.classList.toggle(s.shadow, true);
        });

        if (footerRef.current) {
          t.staggerFromTo(
            footerRef.current.children,
            1,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0 },
            0.075
          );
        }

        if (searchRef.current) {
          t.fromTo(searchRef.current, 1, { opacity: 0, y: 20 }, { opacity: 1, y: 0 }, 1.2);
        }

        return t;
      },

      close() {
        const t = new TimelineMax();

        t.call(() => {
          if (!headerEl.current) return;
          headerEl.current.classList.toggle(s.shadow, false);
        });

        if (mainEl.current && !mainEl.current.style.opacity) {
          t.set(wrapEl.current, { opacity: 0 });
          return t;
        }

        t.fromTo(
          mainEl.current,
          0.2,
          {
            opacity: 1,
          },
          { opacity: 0 }
        );
        t.fromTo(backgroundEl.current, 1, { x: '0%' }, { x: '100%', ease: 'Power3.easeOut' }, 0);
        t.fromTo(brandEl.current, 0.8, { x: '0%' }, { x: '100%', ease: 'Power3.easeOut' }, 0.2);
        // t.set(mainEl.current, { display: 'none' });
        t.set(wrapEl.current, { opacity: 0 });

        return t;
      },

      setImage(index: number) {
        const t = new TimelineMax();

        if (imageRef.current) {
          const els = Array.from(imageRef.current.querySelectorAll(`.${s.navigation__img}`));

          t.set([brandRef.current, maskRef.current], { y: '-100%' });
          els.forEach((el, i) => {
            t.set(el, {
              zIndex: i === index ? 10 : 1,
              opacity: i === index ? 1 : 0,
            });
          });

          t.fromTo(
            imageRef.current,
            2,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, ease: 'Power4.easeOut' }
          );
        }
        return t;
      },

      changeImage(index: number) {
        const t = new TimelineMax();

        if (currentImageIndex === index) {
          return t;
        }

        if (imageRef.current) {
          const els = Array.from(imageRef.current.querySelectorAll(`.${s.navigation__img}`));
          const currentEl = els[index];
          const speed = 1;

          setCurrentImageIndex(index);

          t.fromTo(
            brandRef.current,
            speed,
            { y: '-100%' },
            { y: '100%', ease: 'Power2.easeInOut' },
            0
          );
          t.fromTo(
            maskRef.current,
            speed,
            { y: '-100%' },
            { y: '100%', ease: 'Power2.easeInOut' },
            0.1
          );
          els.forEach((el, i) => {
            t.set(
              el,
              {
                zIndex: i === index ? 10 : 1,
                opacity: i === index ? 1 : 0,
              },
              speed / 2
            );
          });
          // t.set(currentEl, { opacity: 1, zIndex: 10 }, speed / 2);
          t.fromTo(
            currentEl,
            1,
            { scale: 1.2 },
            { scale: 1, opacity: 1, ease: 'Power4.easeOut' },
            speed / 2
          );
        }

        return t;
      },
    }));

    return (
      <>
        <div ref={wrapEl} className={classnames(s.navigation, { [s.isOpen]: isOpen })}>
          <div className={s.navigation__contentLayer} ref={mainEl}>
            <div className={s.navigation__header} ref={headerEl}>
              <Header color="brand" tabIndex={-1} />
            </div>

            <div className={s.navigation__figure}>
              <div className={s.navigation__figureLimit}>
                <div className={s.navigation__figureConstraint}>
                  <div className={s.navigation__image} ref={imageRef}>
                    <div className={s.navigation__mask} ref={maskRef} />
                    <div className={s.navigation__brandMask} ref={brandRef} />

                    {React.Children.map(children, (component: React.ReactElement, i) => {
                      const img = component.props?.image?.main?.url || component.props?.image?.url;
                      return (
                        <div
                          key={i}
                          className={s.navigation__img}
                          style={
                            img && {
                              backgroundImage: `url(${img})`,
                            }
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className={s.navigation__padder}>
              <div className={s.navigation__wrap}>
                <div className={s.navigation__main}>
                  <div className={s.navigation__container}>
                    <div className={s.navigation__row}>
                      <nav className={s.navigation__content}>
                        <ul className={s.navigation__list}>
                          {React.Children.map(children, (component: React.ReactElement, i) => (
                            <li key={i} className={s.navigation__item}>
                              {React.cloneElement(component, {
                                index: i + 1,
                              })}
                            </li>
                          ))}
                          <li ref={searchRef}>
                            <SearchBox iconColor="white" onSearched={onToggle} />
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>

                <div ref={footerRef} className={s.navigation__footer}>
                  <div className={s.navigation__footerContainer}>{footer}</div>
                  {languages && <div className={s.navigation__languages}>{languages}</div>}
                </div>
              </div>
            </div>
          </div>

          <div ref={brandEl} className={s.navigation__brandLayer} />
          <div ref={backgroundEl} className={s.navigation__backgroundLayer} />
        </div>

        <MenuButton
          isOpen={isOpen}
          isDisable={isAnimating}
          onClick={onToggle}
          showDesktop={showDesktop}
        />
      </>
    );
  }
);
