import React from 'react';
import Head from 'next/head';

import { IImage } from 'utils/types';
import { config } from 'utils/config';

type Props = {
  siteTitle: string;
  pageTitle: string;
  description: string;
  image: IImage;
  divider?: string;
  canonicalUrl?: string;
};

export const Meta = ({
  siteTitle,
  pageTitle,
  description,
  image,
  divider = ' - ',
  canonicalUrl,
}: Props) => {
  const title = pageTitle
    ? `${pageTitle}${siteTitle ? ` ${divider} ${siteTitle}` : ''}`
    : siteTitle;
  const faviconPath = `/static/favicons/${config.theme}`;

  return (
    <Head>
      <title>{title}</title>

      <meta name="theme-color" content="#333" />
      <meta name="description" content={description} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image.url} />}

      {image && <meta property="og:image" content={image.url} />}

      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      <link rel="apple-touch-icon" sizes="180x180" href={`${faviconPath}/apple-touch-icon.png`} />
      <link rel="icon" type="image/png" sizes="32x32" href={`${faviconPath}/favicon-32x32.png`} />
      <link rel="icon" type="image/png" sizes="16x16" href={`${faviconPath}/favicon-16x16.png`} />

      {config.prismic?.repository === 'alvogen-is' && (
        <meta name="facebook-domain-verification" content="4lofaroitavvnpxfva6rlhqin56flq" />
      )}
    </Head>
  );
};
