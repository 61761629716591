import React from 'react';

import { PageLayout } from 'components/page-layout/PageLayout';
import { TopNavigationContainer } from 'containers/navigation/TopNavigation';
import { NavigationContainer } from 'containers/navigation/Navigation';
import { IPrismicLayout } from 'providers/prismic/layout';
import { Footer } from 'components/footer/Footer';
import { Devtools } from 'components/devtools/Devtools';
import { Preview } from 'components/preview/Preview';
import { Meta } from 'components/meta/Meta';
import { IImage } from 'utils/types';
import { Warning } from 'containers/warning/Warning';
import { RichText } from 'components/rich-text/RichText';

type Props = {
  divider?: string;
  pageLayout?: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  isPadded?: boolean;
  children: React.ReactNode;
  layout?: IPrismicLayout; // TODO: only pass what we need from home data
  hasFooter?: boolean;
  preview?: boolean;
  warning?: boolean;
  seo?: {
    title?: string;
    description?: string;
    image?: IImage;
    canonicalUrl?: string;
  };
};

export const Layout = ({
  pageLayout = true,
  header,
  footer,
  isPadded = false,
  children,
  layout,
  seo,
  preview = false,
  warning = false,
  hasFooter = false,
}: Props) => {
  const sideNavigation = process.env.NAVIGATION === 'side';
  const [unsupported, setUnsupported] = React.useState<boolean>(false);

  const footerElement = hasFooter ? (
    <Footer
      links={layout?.footer?.links}
      secondaryLinks={layout?.footer?.secondaryLinks}
      socialLinks={layout?.footer?.socialLinks}
      copyright={layout?.footer?.copyright}
      policyLinks={layout?.footer?.policyLinks}
    />
  ) : footer ? (
    footer
  ) : null;

  React.useEffect(() => {
    if (
      typeof CSS === 'undefined' ||
      !CSS.supports('(--foo: red)') ||
      !CSS.supports('font-size', 'clamp(1rem, 2.5vw, 2rem)')
    ) {
      setUnsupported(true);
    }
  }, []);

  return (
    <>
      <Meta
        siteTitle={layout?.seo?.title}
        pageTitle={seo?.title}
        description={seo?.description || layout?.seo?.description}
        image={seo?.image || layout?.seo?.image}
        canonicalUrl={seo?.canonicalUrl}
      />

      {layout?.navigationMain?.length > 0 && (
        <NavigationContainer
          navigationMain={layout?.navigationMain}
          navigationFooter={layout?.navigationFooter}
          showDesktop={sideNavigation}
          languages={layout.language}
        />
      )}

      {layout?.navigationMain?.length > 0 && !sideNavigation && (
        <TopNavigationContainer
          languages={layout.language}
          navigationMain={layout?.navigationMain}
          navigationFooter={layout?.navigationFooter}
        />
      )}

      {!pageLayout && header}

      {pageLayout && (
        <PageLayout
          header={header}
          footer={footerElement}
          isPadded={isPadded}
          navPadding={sideNavigation}
        >
          {children}
        </PageLayout>
      )}
      {!pageLayout && children}

      {!pageLayout && footerElement}

      {/* <Sidebar /> */}
      <Devtools />

      {(warning || layout?.warning?.warning) && (
        <Warning
          visible={warning || layout?.warning?.warning}
          tagline={layout?.warning?.tagline}
          title={layout?.warning?.title}
          text={<RichText render={layout?.warning?.text} />}
          label={layout?.warning?.label}
        />
      )}

      {unsupported && (
        <Warning
          visible={unsupported}
          title="Your browser is out of date"
          text={
            <p>
              This page uses features that are not supported by your browser.
              <br />
              Please consider updating to a newer version.
            </p>
          }
          label="I understand"
        />
      )}

      {preview && <Preview />}
    </>
  );
};
