import React from 'react';
import classnames from 'classnames';

import { Link } from 'components/link/Link';
import ArrowUp from 'components/assets/arrow-up.svg';

import s from './ArrowButton.module.scss';

type Props = {
  color?: 'white' | 'black';
  direction?: 'up' | 'down';
  scrollOnClick?: boolean;
  href?: string;
  className?: string;
  tabIndex?: number;
  onClick?: () => void;
};

export const ArrowButton = ({
  className,
  color = 'black',
  direction = 'up',
  href,
  scrollOnClick,
  ...rest
}: Props) => {
  const [showArrow, setArrowState] = React.useState(false);
  const classNames = classnames(s.button, className, {
    [s.black]: color === 'black',
    [s.down]: direction === 'down',
    [s.hidden]: !showArrow,
  });

  const content = (
    <span className={s.button__icon}>
      <ArrowUp />
    </span>
  );

  if (direction === 'down') {
    rest['aria-label'] = 'Scroll down';
  } else {
    rest['aria-label'] = 'Scroll up';
  }

  React.useEffect(() => {
    const ratio = document.body.offsetHeight / window.innerHeight;

    setArrowState(ratio > 1.5);
  });

  if (scrollOnClick && rest.onClick === undefined) {
    rest.onClick = () => {
      try {
        window.scrollTo({
          top: direction === 'down' ? window.innerHeight : 0,
          behavior: 'smooth',
        });
      } catch (e) {
        window.scrollTo(0, direction === 'down' ? window.innerHeight : 0);
      }
    };
  }

  if (href) {
    return (
      <Link className={classNames} {...rest}>
        {content}
      </Link>
    );
  }

  return (
    <button className={classNames} {...rest}>
      {content}
    </button>
  );
};
