import React from 'react';
import classnames from 'classnames';

import { useUI } from 'utils/ui';

import s from './Background.module.scss';

const BackgroundColorContext = React.createContext<string>('white');

export const useBackgroundColor = () => React.useContext(BackgroundColorContext);

type Props = {
  currentBackground?: 'white' | 'dark' | 'brand' | 'image' | 'grey';
  children: React.ReactNode;
};

export const Background = ({ currentBackground, children }: Props) => {
  const ui = useUI();
  const [color, setColor] = React.useState((currentBackground as string) || '');

  React.useEffect(() => {
    const handleScroll = () => {
      const item = ui.getCurrentSection(window.pageYOffset);
      setColor(item ? item.color : '');
    };

    if (currentBackground) {
      setColor(currentBackground);
      return;
    }

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    const timer1 = setTimeout(() => handleScroll(), 100);
    const timer2 = setTimeout(() => handleScroll(), 300);
    const timer3 = setTimeout(() => handleScroll(), 1000);
    const timer4 = setTimeout(() => handleScroll(), 1200);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
    };
  }, [currentBackground, ui.backgroundSections]);

  // set body color class
  React.useEffect(() => {
    document.body.classList.remove('brand', 'white', 'dark', 'image');
    if (color) {
      document.body.classList.add(color);
    }
  }, [color]);

  return (
    <div className={classnames(s.background, s[color])}>
      <BackgroundColorContext.Provider value={color}>{children}</BackgroundColorContext.Provider>
    </div>
  );
};
