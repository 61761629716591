import React from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { localeUrlResolver } from 'utils/localeUrlResolver';
import { useUI } from 'utils/ui';
import { config } from 'utils/config';
import { Warning } from 'components/modal/Warning';
import { Modal } from 'components/modal/Modal';
import { Buttons } from 'components/buttons/Buttons';
import { Button } from 'components/button/Button';
import { Trans } from '@lingui/macro';

type Props = {
  href?: string | { pathname: string; query: any };
  target?: string;
  className?: string;
  children: React.ReactNode;
  locale?: string;
  transition?: boolean;
  onClick?: (event) => void;
  [rest: string]: any;
};

export const Link = ({
  href,
  target,
  className,
  children,
  locale,
  transition = true,
  onClick: customOnClick,
  ...rest
}: Props) => {
  const router = useRouter();
  const ui = useUI();
  const [externalWarning, setExternalWarning] = React.useState<boolean>(false);

  // console.log('Show external link warning', config.externalLinkWarning);

  const hideWarning = () => {
    setExternalWarning(false);
  };

  const navigate = () => {
    setExternalWarning(false);
    window.open(href as string, target);
  };

  if (!href || (typeof href !== 'string' && !href.pathname)) return null;

  const handleClick = (e) => {
    let isPropagationStopped = false;
    const isExternal =
      typeof href === 'string' && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(href || '');
    const isException = typeof href === 'string' && href.includes('prismic-io');

    // Make sure user can still ctrl/command click links to open in new tab
    if (e.ctrlKey || e.metaKey) {
      return;
    }

    if (customOnClick) {
      customOnClick(e);

      isPropagationStopped = e.isPropagationStopped && e.isPropagationStopped();

      if (e.isDefaultPrevented()) {
        return;
      }
    }

    if (isExternal && config.externalLinkWarning && !isException) {
      e.preventDefault();
      setExternalWarning(true);
      return;
    }

    if (
      ui.isPageTransitioning ||
      href === '#' ||
      (typeof href !== 'string' && href.pathname === '#') ||
      isExternal
    ) {
      return;
    }

    if (transition) {
      e.preventDefault();
    } else {
      return;
    }

    if (window.location.pathname === href) {
      ui.toggleNavigation(false);
      return;
    }

    ui.togglePageTransition(true);

    setTimeout(
      () => {
        router.push(href);

        if (scroll) {
          window.scrollTo(0, 0);
        }
      },
      transition ? config.animations.pageTransition.showDuration : 0
    );
  };

  if (typeof href === 'string') {
    const isExternal = /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(href || '');

    // check if the link switchtes between locales
    const localeHref = locale ? href : localeUrlResolver(router, href);

    const main = (
      <>
        <a
          className={className}
          href={localeHref}
          target={target}
          rel={isExternal ? 'noopener noreferrer' : null}
          onClick={handleClick}
          {...rest}
        >
          {children}
        </a>
        {externalWarning && (
          <Modal
            open={externalWarning}
            onClose={hideWarning}
            openClass="scroll"
            footer={
              <Buttons alignment="right">
                <Button onClick={navigate}>
                  <Trans>Continue</Trans>
                </Button>
              </Buttons>
            }
          >
            <Warning
              tagline=""
              title=""
              text={<Trans>This link leads to an external website</Trans>}
            />
          </Modal>
        )}
      </>
    );

    if (!isExternal && localeHref) {
      return (
        <NextLink href={localeHref} locale={locale} prefetch={false} {...rest}>
          {main}
        </NextLink>
      );
    }

    return main;
  } else if (href.pathname) {
    const localeHref = locale
      ? href
      : { ...href, pathname: localeUrlResolver(router, href.pathname) };
    return (
      <NextLink href={localeHref} locale={locale} prefetch={false} {...rest} legacyBehavior>
        <a className={className} target={target} onClick={handleClick} {...rest}>
          {children}
        </a>
      </NextLink>
    );
  }
};
