import React from 'react';
import classnames from 'classnames';

import { config } from 'utils/config';
import { ILink } from 'utils/types';
import { Link } from 'components/link/Link';
import { ArrowButton } from 'components/button/ArrowButton';
import { BrandLogo } from 'components/assets';
import SocialIconFacebook from 'components/assets/social-icon-facebook.svg';
import SocialIconTwitter from 'components/assets/social-icon-twitter.svg';
import SocialIconLinkedin from 'components/assets/social-icon-linkedin.svg';
import SocialIconCrunchbase from 'components/assets/cb.svg';

import s from './Footer.module.scss';

type Props = {
  links?: Array<{
    text: string;
    link: ILink;
  }>;
  secondaryLinks?: Array<{
    text: string;
    link: ILink;
  }>;
  socialLinks?: Array<{
    platform: string;
    link: ILink;
  }>;
  policyLinks?: Array<{
    text: string;
    link: ILink;
  }>;
  copyright?: string;
};

export const Footer = ({ links, secondaryLinks, socialLinks, policyLinks, copyright }: Props) => {
  const lang = config.i18n.default === 'is' ? 'is' : 'en';

  const socialIcon = (type) => {
    switch (type) {
      case 'Facebook':
        return <SocialIconFacebook />;

      case 'Twitter':
        return <SocialIconTwitter />;

      case 'LinkedIn':
        return <SocialIconLinkedin />;

      case 'crunchbase':
        return <SocialIconCrunchbase />;

      default: {
        console.warn('Unhandled social icon type:', `type: ${type}`);
        return <></>;
      }
    }
  };

  return (
    <div className={s.footer}>
      <div className={s.footer__container}>
        <div className={s.footer__content}>
          <div className={s.footer__side}>
            <ArrowButton tabIndex={-1} direction="up" color="white" scrollOnClick />
          </div>

          <div className={s.footer__navigation}>
            <nav className={classnames(s.footer__nav, s.main)}>
              <ul className={s.footer__navList}>
                {links?.map((item, i) => (
                  <li className={s.footer__navItem} key={i}>
                    <Link
                      key={item.text}
                      className={s.footer__navLink}
                      // {...item.link}
                      href={item.link?.href}
                      uid={item.link?.uid}
                    >
                      {item.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div className={s.footer__brand}>
            <Link className={s.footer__logo} href="/" aria-label="Logo">
              <BrandLogo />
            </Link>
          </div>

          <div className={s.footer__contact}>
            <nav className={s.footer__nav}>
              <ul className={s.footer__navList}>
                {secondaryLinks?.map((item, i) => (
                  <li className={s.footer__navItem} key={i}>
                    {item.link ? (
                      <Link
                        key={item.text}
                        className={s.footer__navLink}
                        // {...item.link}
                        href={item.link?.href}
                        uid={item.link?.uid}
                      >
                        {item.text}
                      </Link>
                    ) : (
                      <div className={s.footer__navNonLink}>{item.text}</div>
                    )}
                  </li>
                ))}
                {config.isDev && (
                  <li className={s.footer__navItem}>
                    <Link className={s.footer__navLink} href="/elements">
                      Elements
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>

          <div className={s.footer__social}>
            <nav className={classnames(s.footer__nav, s.social)}>
              <ul className={s.footer__navList}>
                {socialLinks
                  ?.filter((item) => item.platform)
                  ?.map((item, i) => (
                    <li className={s.footer__navItem} key={i}>
                      <Link
                        key={item.platform}
                        className={s.footer__navLink}
                        aria-label={item.platform}
                        // {...item.link}
                        href={item.link.href}
                        uid={item.link.uid}
                      >
                        {socialIcon(item.platform)}
                      </Link>
                    </li>
                  ))}
              </ul>
            </nav>
          </div>
        </div>

        <div className={s.footer__bottom}>
          {policyLinks?.length > 0 && (
            <ul className={s.footer__legalList}>
              {policyLinks?.map((item, i) => (
                <li key={i} className={s.footer__policy}>
                  <Link
                    key={item.text}
                    className={s.footer__link}
                    // {...item.link}
                    href={item.link?.href}
                    uid={item.link?.uid}
                  >
                    {item.text}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <div className={s.footer__addendum}>
            <p className={s.footer__copy}>{copyright}</p>
            {config.ist85certification && (
              <div className={s.footer__certification}>
                <img src={`/static/images/ist85_white_${lang}.png`} width={107} height={54} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
