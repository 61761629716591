import React from 'react';
import classnames from 'classnames';
import { IImage } from 'utils/types';

import s from './TopNavigation.module.scss';

type Props = {
  image: IImage;
  isActive?: boolean;
  children?: React.ReactNode;
};

export const Menu = ({ image, isActive, children }: Props) => {
  React.useEffect(() => {}, [isActive]);
  const childCount = React.Children.count(children);

  const dropImage = image?.desktop?.url ?? image?.url;

  return (
    <div className={classnames(s.menu, { [s.isActive]: isActive, [s.single]: childCount < 4 })}>
      <div className={s.menu__layout}>
        <div
          className={s.menu__image}
          style={dropImage && { backgroundImage: `url(${dropImage})` }}
        ></div>
        <div className={s.menu__navigation}>{children}</div>
      </div>
    </div>
  );
};
