const rewrites = [
  {
    source: '/robots.txt',
    destination: '/api/robots',
  },
  {
    source: '/sitemap.xml',
    destination: '/api/sitemap',
  },
  {
    source: '/api/feed.xml',
    destination: '/api/feed'
  }
];

module.exports = rewrites;
