import React from 'react';
import { useRouter } from 'next/router';
import { TimelineMax } from 'gsap';

import { useUI } from 'utils/ui';
import { IImage, ILink } from 'utils/types';
import { Navigation, NavigationRef } from 'components/navigation/Navigation';
import { NavigationSection } from 'components/navigation/NavigationSection';
import { NavigationItem } from 'components/navigation/NavigationItem';
import { NavigationFooterItem } from 'components/navigation/NavigationFooterItem';
import { Languages } from 'components/top-navigation/Languages';

type Props = {
  navigationMain?: Array<{
    title: string;
    link: ILink;
    image: IImage;
    items: Array<{
      title: string;
      link: ILink;
      nested: boolean;
      showInNavigation: boolean;
    }>;
  }>;
  navigationFooter?: Array<{
    text: string;
    link: ILink;
  }>;
  showDesktop?: boolean;
  languages?: Array<{
    label: string;
    link: ILink;
  }>;
};

export const NavigationContainer = ({
  navigationMain,
  navigationFooter,
  showDesktop,
  languages,
}: Props) => {
  const router = useRouter();
  const ui = useUI();
  const [sections] = React.useState(new Set<any>());
  const navigationRef = React.useRef<NavigationRef | null>(null);

  const onSectionClick = (sectionRef: any) => {
    const arr = Array.from(sections.values());
    const t = new TimelineMax();

    arr.forEach((ref: any) => ref.current && ref !== sectionRef && t.add(ref.current.hide(), 0));

    t.add(sectionRef.current.show(), 0);
    t.add(navigationRef.current.changeImage(arr.indexOf(sectionRef)), 0);
  };

  React.useEffect(() => {
    if (!navigationRef.current) {
      return;
    }

    const arr = Array.from(sections.values());
    const timeline = new TimelineMax();

    timeline.call(() => ui.toggleNavigationAnimating(true), null, null, 0);

    if (ui.isNavigationOpen) {
      const sectionOpenIndex = arr?.map((x) => x.current.isActive()).indexOf(true);

      // console.log(arr.map((x) => x.current.isActive()));

      timeline.call(() => arr[sectionOpenIndex] && arr[sectionOpenIndex].current.calculateHeight());
      timeline.add(navigationRef.current.open());
      timeline.add(
        navigationRef.current.setImage(sectionOpenIndex !== -1 ? sectionOpenIndex : 0),
        0.75
      );
      timeline.call(() => ui.toggleNavigationAnimating(false));
      arr.forEach(
        (ref: any, index: number) =>
          ref.current && timeline.add(ref.current.reveal(), `${index * 0.15 + 1}`)
      );
    } else {
      timeline.add(navigationRef.current.close());
      timeline.call(() => ui.toggleNavigationAnimating(false));
    }

    if (ui.isPageTransitioning) {
      timeline.progress(1);
    }

    return () => timeline && timeline.kill();
  }, [ui.isNavigationOpen]);

  const isSectionActive = (router, slug) => {
    if (!router.query.parentId) {
      const split = router.asPath.split('/').filter((x) => x !== '');
      const first = split[0];

      return first === slug;
    }

    return router.query.parentId === slug;
  };

  const isItemActive = (router, uid) => {
    return router.asPath === uid;
  };

  return (
    <Navigation
      showDesktop={showDesktop}
      ref={navigationRef}
      isOpen={ui.isNavigationOpen}
      isAnimating={ui.isNavigationAnimating}
      onToggle={() => ui.toggleNavigation()}
      footer={navigationFooter?.map((item) => (
        <NavigationFooterItem key={item.text} link={item.link}>
          {item.text}
        </NavigationFooterItem>
      ))}
      languages={<Languages languages={languages} mobile />}
    >
      {navigationMain?.map((nav, index) => {
        const ref = React.useRef();

        sections.add(ref);

        return (
          <NavigationSection
            ref={ref}
            key={index}
            image={nav.image}
            title={nav.title}
            href={nav.link?.href}
            isActive={isSectionActive(router, nav.link?.href)}
            onTitleClick={() => onSectionClick(ref)}
          >
            {nav.items
              ?.filter((item) => item.showInNavigation)
              .filter((item) => !!item.link)
              .map((item, i) => (
                <NavigationItem
                  key={i}
                  link={item.link}
                  isActive={isItemActive(router, item.link?.href)}
                >
                  {item.title}
                </NavigationItem>
              ))}
          </NavigationSection>
        );
      })}
    </Navigation>
  );
};
