import React from 'react';
import classnames from 'classnames';
import { TimelineMax } from 'gsap';

import { Link } from 'components/link/Link';
import { IImage } from 'utils/types';

import s from './Navigation.module.scss';

type Props = {
  index?: number;
  title: string;
  isActive?: boolean;
  image: IImage;
  href: string;
  onTitleClick?: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const NavigationSection = React.forwardRef(
  ({ index, title, isActive, href, onTitleClick, onClick, children }: Props, ref: any) => {
    const [isSelected, setSelected] = React.useState(false);
    const [state, setState] = React.useState(false);
    const [height, setHeight] = React.useState(0);
    const sectionEl = React.useRef<HTMLDivElement | null>(null);
    const listEl = React.useRef<HTMLDivElement | null>(null);
    const itemsEl = React.useRef<HTMLDivElement | null>(null);
    const hasChildren = React.Children.toArray(children).length > 0;

    React.useImperativeHandle(ref, () => ({
      calculateHeight() {
        if (listEl.current && listEl.current.firstChild) {
          const listElChild = listEl.current.firstChild as HTMLDivElement;
          const h = listElChild.offsetHeight;

          setHeight(h);

          if (isActive) {
            ref.current.show({ heightOverwrite: h });
          } else {
            ref.current.hide();
          }
        }
      },

      show: ({ heightOverwrite }: { heightOverwrite?: number } = {}) => {
        const t = new TimelineMax();

        if (!sectionEl.current || !listEl.current || !itemsEl.current || state || !hasChildren) {
          return t;
        }

        setState(true);

        t.call(() => setSelected(true));
        t.to(listEl.current, 1, { height: heightOverwrite || height, ease: 'Power4.easeOut' }, 0);
        t.staggerFromTo(
          itemsEl.current.children,
          0.4,
          { opacity: 0, y: -20 },
          { opacity: 1, y: 0, ease: 'Power4.easeOut' },
          0.04,
          '-=0.75'
        );

        return t;
      },

      hide: () => {
        const t = new TimelineMax();

        if (!sectionEl.current || !listEl.current || !itemsEl.current) {
          return t;
        }

        t.call(() => setSelected(false));
        t.to(listEl.current, 0.75, { height: 0, ease: 'Power4.easeOut' }, 0);
        t.to(itemsEl.current.children, 0.15, { opacity: 0, ease: 'Power4.easeOut' }, 0);

        setState(false);

        return t;
      },

      reveal: () => {
        const t = new TimelineMax();

        if (!sectionEl.current || !listEl.current) {
          return t;
        }

        ref.current.calculateHeight();

        t.fromTo(sectionEl.current, 1, { opacity: 0, y: 20 }, { opacity: 1, y: 0 });

        return t;
      },

      isActive: () => isActive,
    }));

    React.useEffect(() => {
      if (ref.current) ref.current.calculateHeight();
    }, []);

    return (
      <div ref={sectionEl} className={classnames(s.section, { [s.isActive]: isSelected })}>
        <div className={s.section__header} onClick={onTitleClick}>
          {!hasChildren && (
            <Link href={href} className={s.section__limit}>
              <span className={s.section__index}>0{index}</span>
              <span className={s.section__title}>{title}</span>
            </Link>
          )}
          {hasChildren && (
            <div className={s.section__limit}>
              <span className={s.section__index}>0{index}</span>
              <span className={s.section__title}>{title}</span>
            </div>
          )}
        </div>

        <div className={s.section__list} ref={listEl}>
          <div className={s.section__listOuter}>
            <div className={s.section__listInner} ref={itemsEl}>
              {React.Children.map(children, (component: JSX.Element) =>
                React.cloneElement(component, { onClick })
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
