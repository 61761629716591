import React from 'react';
import classnames from 'classnames';

import { useUI } from 'utils/ui';
import { Link } from 'components/link/Link';
import { BrandLogo } from 'components/assets';

import s from './Header.module.scss';

type Props = {
  fixed?: boolean;
  color?: 'brand' | 'white' | 'black' | string;
  tabIndex?: number;
  onClick?: () => void;
  showNavigation?: boolean;
};

export const Header = ({ fixed, color, tabIndex = 1, onClick, showNavigation }: Props) => {
  const { isTopNavigationOpen } = useUI();

  return (
    <>
      <div className={classnames(s.header, { [s.fixed]: fixed })}>
        <div className={s.header__container}>
          <Link
            className={classnames(s.header__logo, {
              [s.white]: color === 'white',
              [s.yellow]: isTopNavigationOpen || color === 'brand',
              [s.main]: tabIndex === 1,
              [s.navOpen]: isTopNavigationOpen,
            })}
            href="/"
            onClick={onClick}
            aria-label="Return to home page"
            tabIndex={tabIndex}
            id={tabIndex === 1 ? 'logo' : ''}
          >
            <BrandLogo />
          </Link>
        </div>
      </div>
      {showNavigation && (
        <a className={s.header__skip} tabIndex={tabIndex} href="#main">
          Skip Navigation
        </a>
      )}
    </>
  );
};
