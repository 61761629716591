import React from 'react';
import classnames from 'classnames';
import { TweenLite } from 'gsap';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import IconSearch2 from 'components/assets/icon-search-2.svg';
import { Languages } from './Languages';
import { ILink } from 'utils/types';
import s from './TopNavigation.module.scss';

type Props = {
  topNav?: React.ReactNode;
  mainNav?: React.ReactNode;
  children: React.ReactNode;
  isOpen?: boolean;
  menuIndex?: number;
  languages?: Array<{
    label: string;
    link: ILink;
  }>;
  onToggleSearch?: (e: any) => void;
};

export const TopNavigation = React.forwardRef<HTMLElement, Props>(
  ({ topNav, mainNav, children, isOpen, menuIndex, languages, onToggleSearch }: Props, ref) => {
    const { i18n } = useLingui();
    const dropdownEl = React.useRef<HTMLDivElement>();

    const toggleSearch = (e) => {
      onToggleSearch(e);
    };

    React.useEffect(() => {
      if (isOpen) {
        TweenLite.set(dropdownEl.current, { height: 'auto' });
        TweenLite.from(dropdownEl.current, 0.6, {
          height: 0,
          ease: 'Power3.easeOut',
        });
      } else {
        TweenLite.to(dropdownEl.current, 0.5, {
          height: 0,
          ease: 'Power3.easeOut',
        });
      }
    }, [isOpen]);

    React.useEffect(() => {
      if (isOpen) {
        const oldHeight = dropdownEl.current.offsetHeight;
        TweenLite.set(dropdownEl.current, { height: 'auto' });
        const newHeight = dropdownEl.current.offsetHeight;
        TweenLite.fromTo(
          dropdownEl.current,
          0.6,
          {
            height: oldHeight,
          },
          {
            height: newHeight,
            ease: 'Power3.easeOut',
          }
        );
      }
    }, [menuIndex]);

    return (
      <nav ref={ref} className={classnames(s.nav, { [s.isOpen]: isOpen })}>
        <div className={s.nav__nav}>
          <div className={s.nav__top}>
            {topNav}

            <Languages languages={languages} />

            {process.env.SEARCH_ENABLED === 'true' && (
              <button
                className={s.nav__toggle}
                onClick={toggleSearch}
                aria-label={i18n._(t`Toggle Search`)}
              >
                <IconSearch2 />
              </button>
            )}
          </div>
          <div className={s.nav__main}>{mainNav}</div>
        </div>
        <div ref={dropdownEl} className={s.nav__dropdown}>
          <div className={s.nav__container}>{children}</div>
        </div>
      </nav>
    );
  }
);
