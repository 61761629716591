import { NextRouter } from 'next/router';
import { pathToRegexp, match, compile } from 'path-to-regexp';
import rewrites from 'rewrites';

// From https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/i18n/normalize-locale-path.ts
export function normalizeLocalePath(
  pathname: string,
  locales?: string[]
): {
  detectedLocale?: string;
  pathname: string;
} {
  let detectedLocale: string | undefined;
  // first item will be empty string from splitting at first char
  const pathnameParts = pathname.split('/');

  (locales || []).some((locale) => {
    if (pathnameParts?.[1]?.toLowerCase() === locale.toLowerCase()) {
      detectedLocale = locale;
      pathnameParts.splice(1, 1);
      pathname = pathnameParts.join('/') || '/';
      return true;
    }
    return false;
  });

  return {
    pathname,
    detectedLocale,
  };
}

export const localeUrlResolver = (router: NextRouter, url: string = '') => {
  const isExternal = /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(url || '');
  if (isExternal || url === '') return url;

  const [baseUrl, query] = url.split('?');

  // Check if a rewrite rule exists for this url and locale
  const rewriteRule = rewrites.find((rw) => {
    const regex = pathToRegexp(rw.destination);
    if (regex.test(baseUrl)) {
      const nlp = normalizeLocalePath(rw.source, router.locales);
      return router.locale === nlp.detectedLocale;
    }
    return false;
  });

  if (!rewriteRule) {
    const nlp = normalizeLocalePath(url, router.locales);
    if (router.locale !== router.defaultLocale && !nlp.detectedLocale) {
      return `/${router.locale}${url === '/' ? '' : url}`;
    }
    return url;
  }

  const sourceNormalized = normalizeLocalePath(rewriteRule.source, router.locales);

  const compileToPath = compile(sourceNormalized.pathname);
  const matchDestination = match(rewriteRule.destination);

  const destination = matchDestination(baseUrl);

  // @ts-ignore
  const path = compileToPath(destination.params);

  const localePath = query ? `${path}?${query}` : path;

  if (router.locale !== router.defaultLocale) {
    return `/${router.locale}${localePath}`;
  }

  return localePath;
};
