import React from 'react';
// import dynamic from 'next/dynamic';
import { config } from 'utils/config';
import GridOverlay from './GridOverlay';

export const LOCAL_STORAGE_KEY_VERTICAL = '_devtoolsVerticalGridVisible';

// const DynamicComponentWithNoSSR = dynamic(import('./GridOverlay.tsx'), {
//   ssr: false,
// });

export const Devtools = () => {
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    const onKeyDown = (e: any) => {
      if (e.ctrlKey && e.keyCode === 76) {
        setState((currentState) => {
          localStorage.setItem(LOCAL_STORAGE_KEY_VERTICAL, !currentState ? '1' : '0');

          return !currentState;
        });
      }
    };

    setState(localStorage && localStorage.getItem(LOCAL_STORAGE_KEY_VERTICAL) === '1');

    window.document.addEventListener('keydown', onKeyDown);

    return () => {
      window.document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return state && config.loadDevTools ? <GridOverlay /> : null;
};
