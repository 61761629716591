import React from 'react';
import { Modal } from 'components/modal/Modal';
import { Warning as ModalWarning } from 'components/modal/Warning';
import { Buttons } from 'components/buttons/Buttons';
import { Button } from 'components/button/Button';

type Props = {
  visible: boolean;
  tagline?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  label?: React.ReactNode;
};

export const Warning = ({ visible, tagline, title, text, label }: Props) => {
  const [showWarning, setShowWarning] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (window.sessionStorage && !window.sessionStorage.getItem('SHOW_WARNING')) {
      setShowWarning(visible);
      return window.sessionStorage.setItem('SHOW_WARNING', visible ? '1' : '0');
    }

    setShowWarning(() => {
      if (window.sessionStorage && window.sessionStorage.getItem('SHOW_WARNING')) {
        return window.sessionStorage.getItem('SHOW_WARNING') === '1';
      }
    });
  }, []);

  const hideWarning = () => {
    setShowWarning(false);
    window.sessionStorage.setItem('SHOW_WARNING', '0');
  };

  return (
    <Modal
      open={showWarning}
      onClose={hideWarning}
      footer={
        <Buttons alignment="right">
          <Button onClick={hideWarning}>{label || 'Ok'}</Button>
        </Buttons>
      }
    >
      <ModalWarning tagline={tagline} title={title} text={text} />
    </Modal>
  );
};
