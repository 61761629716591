import React from 'react';

import s from './Modal.module.scss';

type Props = {
  tagline: React.ReactNode;
  title: React.ReactNode;
  text: React.ReactNode;
};

export const Warning = ({ tagline, title, text }: Props) => {
  return (
    <div className={s.warning}>
      {tagline && <p className={s.warning__tagline}>{tagline}</p>}
      {title && <h2 className={s.warning__title}>{title}</h2>}
      {text && <div className={s.warning__text}>{text}</div>}
    </div>
  );
};
