import React from 'react';
import classnames from 'classnames';
import ReactModal from 'react-modal';

import s from './Modal.module.scss';

type Props = {
  open?: boolean;
  blur?: boolean;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  shouldCloseOnOverlayClick?: boolean;
  openClass?: string;
  onClose?: () => void;
};

export const Modal = ({
  open,
  blur,
  children,
  footer,
  shouldCloseOnOverlayClick = true,
  openClass,
  onClose = () => {},
}: Props) => {
  ReactModal.setAppElement('#__next');

  return (
    <ReactModal
      isOpen={open}
      className={s.modal__box}
      overlayClassName={classnames(s.modal, { [s.blur]: blur })}
      bodyOpenClassName={openClass || 'no-scroll'}
      htmlOpenClassName={openClass || 'no-scroll'}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className={s.modal__body}>
        <button className={s.modal__close} onClick={onClose}>
          <span className={s.modal__cross} />
          <span className={s.modal__cross} />
        </button>
        <div>{children}</div>
      </div>
      <footer className={s.modal__footer}>{footer}</footer>
    </ReactModal>
  );
};
