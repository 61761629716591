import React from 'react';
import classnames from 'classnames';

import { Background } from 'components/background/Background';

import s from './PageLayout.module.scss';

type Props = {
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  isPadded?: boolean;
  navPadding?: boolean;
};

export const PageLayout = ({
  children,
  header,
  footer,
  isPadded = false,
  navPadding = true,
}: Props) => {
  return (
    <div className={classnames(s.pageLayout, { [s.sidenav]: navPadding })} id="pageLayout">
      {header}
      <Background>
        {isPadded ? (
          <div className={s.pageLayout__padding} id="main">
            {children}
          </div>
        ) : (
          children
        )}
      </Background>
      {footer}
    </div>
  );
};
